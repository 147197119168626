import { Directive, HostListener, Input, Output, EventEmitter, ElementRef, Renderer2, OnInit } from '@angular/core';

@Directive({
  selector: '[editMode]'
})
export class EditInPlaceDirective implements OnInit {
  @Input('editMode') editMode: boolean = true;
  @Input('safeHTML') safeHTML: boolean = false;
  @Output() editComplete = new EventEmitter<string>();
  private originalText: string = '';

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    
  }

  public ngOnInit(): void {
    this.renderer.addClass(this.el.nativeElement, 'edit-in-place');
    this.renderer.addClass(this.el.nativeElement, 'edit-in-place-view');

    // Adiciona um ícone de edição do lado direito do elemento usando font-awesome, não por dentro
    const icon = this.renderer.createElement('i');
    this.renderer.addClass(icon, 'fa-solid');
    this.renderer.addClass(icon, 'fa-pen-to-square');
    this.renderer.addClass(icon, 'edit-in-place-icon');
    this.el.nativeElement.after(icon);
  }

  private _enableEdit(): void {
    this.originalText = this.el.nativeElement.innerText;
    this.el.nativeElement.contentEditable = 'true';
    this.el.nativeElement.focus();
    this.renderer.addClass(this.el.nativeElement, 'edit-in-place-edit');
    this.renderer.removeClass(this.el.nativeElement, 'edit-in-place-view');
  }

  private _disableEdit(save: boolean = true): void {
    this.el.nativeElement.contentEditable = 'false';
    if (save) {
      if (this.safeHTML) {
        this.editComplete.emit(this.el.nativeElement.innerHTML.trim());
      } else {
        this.editComplete.emit(this.el.nativeElement.innerText.trim());
      }
    } else {
      this.el.nativeElement.innerText = this.originalText;
    }
    this.renderer.addClass(this.el.nativeElement, 'edit-in-place-view');
    this.renderer.removeClass(this.el.nativeElement, 'edit-in-place-edit');
  }

  @HostListener('click') onClick() {
    if (this.editMode) {
      this._enableEdit();
    }
  }

  @HostListener('blur') onBlur() {
    if (this.editMode) {
      this._disableEdit();
    }
  }

  @HostListener('keydown.enter', ['$event']) onEnter(event: KeyboardEvent) {
    if (this.editMode) {
      this._disableEdit();
      event.preventDefault();
    }
  }

  @HostListener('keydown.escape', ['$event']) onEscape(event: KeyboardEvent) {
    if (this.editMode) {
      this._disableEdit(false);
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardHtml = event.clipboardData?.getData('text/html') ?? '';
    const container = document.createElement('div');
    container.innerHTML = clipboardHtml;
    container.innerHTML = container.innerHTML.replace(/<\/?(?!strong|br)[^>]+>/gi, '');
    document.execCommand('insertHTML', false, container.innerHTML.trim());
  }
}

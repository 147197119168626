import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { PhoneOnlyDigitsPipe, PhonePipe } from "./pipes/phonenumber.pipe";
import { LoadingTextComponent } from "./components/loading-text/loading-text.component";
import { DateFromNowPipePipw as DateFromNowPipePipe } from "./pipes/dates.pipe";
import { OrderByPipe } from "./pipes/order.pipe";
import { LeadOptionsPickerComponent } from "./components/lead-options-picker/lead-options-picker.component";
import { NgxMaskDirective, provideNgxMask } from "ngx-mask";
import { EditInPlaceDirective } from './directives/edit-in-place.directive';
import { AvatarDirective } from "./directives/avatar.directive";

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule, 
    FormsModule, 
    NgbModule,
    NgOptimizedImage,
    NgxMaskDirective,
    PhonePipe
  ],
  exports: [
    CommonModule,
    NgOptimizedImage,
    ReactiveFormsModule, 
    FormsModule, 
    NgbModule,
    PhoneOnlyDigitsPipe,
    DateFromNowPipePipe,
    OrderByPipe,
    LoadingTextComponent,
    LeadOptionsPickerComponent,
    PhonePipe,
    EditInPlaceDirective,
    AvatarDirective
  ],
  declarations: [
    PhoneOnlyDigitsPipe,
    DateFromNowPipePipe,
    OrderByPipe,
    LoadingTextComponent,
    LeadOptionsPickerComponent,
    EditInPlaceDirective,
    AvatarDirective
  ],
  providers: [provideNgxMask()]
  
})
export class SharedModule {}

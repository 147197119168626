import { Directive, OnInit, ElementRef, Renderer2, Input } from '@angular/core';

@Directive({
  selector: 'img[avatar]'
})
export class AvatarDirective implements OnInit {

  @Input('avatar') customAvatar?: string;

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  ngOnInit(): void {
    const fallback = `/assets/images/avatars/${Math.floor(Math.random() * 100) + 1}.png`;

    if (this.customAvatar) {
      this.renderer.setAttribute(this.el.nativeElement, 'src', this.customAvatar);
      this.el.nativeElement.onerror = () => {
        this.renderer.setAttribute(this.el.nativeElement, 'src', fallback);
      };
    } else {
      this.renderer.setAttribute(this.el.nativeElement, 'src', fallback);
    }
  }
}
